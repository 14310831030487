import React, { useMemo } from 'react';

import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import { useTranslation } from 'react-i18next';
import AdminTable, { DataCols } from 'src/components/admin-table/admin-table';
import Button from 'src/components/button/button';
import ChipSquared from 'src/components/chip-squared/chip-squared';
import ExpansionList from 'src/components/expansion-list/expansion-list';
import Icon from 'src/components/icon/icon';
import Panel from 'src/components/panel/panel';
import TablePanelTitle from 'src/components/table-panel-title/table-panel-title';
import { PageMaxWidth } from 'src/components/util-components/util-components';
import { allMembers, CollegeMemberType } from 'src/utils/typedefs/college-membership';

// TODO: implement remaining stuff when details are provided
const CollegeMembershipActiveMembershipsTab: React.FC = () => {
  const { t } = useTranslation();

  return (
    <PageMaxWidth>
      <Panel>
        <Grid container={true} justify="space-between">
          <TablePanelTitle title={t('team')} />
          {/*<Link to="/college/team/add-team-member">*/}
          <Button>
            {<Icon name="sm-add" light={true} />} {t('team member')}
          </Button>
          {/*</Link>*/}
        </Grid>
        <Grid item xs={12}>
          <CollegeMembershipActiveMembershipsTable members={allMembers} />
        </Grid>
      </Panel>
    </PageMaxWidth>
  );
};

interface CollegeMembershipActiveMembershipsTableProps {
  members: CollegeMemberType[];
}

const CollegeMembershipActiveMembershipsTable: React.FC<CollegeMembershipActiveMembershipsTableProps> = ({
  members,
}) => {
  const { t } = useTranslation();

  const cols: DataCols<CollegeMemberType> = useMemo(
    () => [
      { key: 'name', title: t('name'), getValue: (member) => member.name },
      {
        key: 'startDate',
        title: t('start date'),
        getValue: (member) => member.startDate,
      },
      {
        key: 'endDate',
        title: t('end date'),
        getValue: (member) => member.endDate,
      },
      {
        key: 'cost',
        title: t('cost'),
        getValue: (member) => <ChipSquared label={t(member.name)} />,
      },
      {
        key: 'status',
        title: t('status'),
        getValue: (member) => <ChipSquared label={t(member.status)} />,
      },
    ],
    [],
  );

  return (
    <>
      <Box display={{ xs: 'none', sm: 'block' }}>
        <AdminTable columns={cols} data={members} />
      </Box>
      <Box display={{ xs: 'block', sm: 'none' }}>
        <ExpansionList columns={cols} data={members} />
      </Box>
    </>
  );
};

export default CollegeMembershipActiveMembershipsTab;
