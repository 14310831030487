import React from 'react';

import CollegeMembershipHome from 'src/components/college-membership-home/college-membership-home';
import Layout from 'src/components/layout/layout';
import SEO from 'src/components/seo';
import { usePrivateRoute } from 'src/utils/auth';

const IndexPage: React.FC = () => {
  usePrivateRoute();
  return (
    <Layout>
      <SEO title="College Memberships" />
      <CollegeMembershipHome />
    </Layout>
  );
};

export default IndexPage;
